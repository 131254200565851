<template>
	<div>
		<Header></Header>
		<maincontent></maincontent>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/Header/index.vue'
	import Footer from '@/components/Footer/index.vue'
	import maincontent from '@/views/layout/maincontent.vue'


	export default {
		name: "app",
		components: {
			Header,
			Footer,
			maincontent,
		}
	}
</script>

<style>
</style>
